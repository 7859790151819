/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Larsseit Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Larsseit Regular"), url("LarsseitItalic.woff") format("woff");
}

@font-face {
  font-family: "Larsseit Thin";
  font-style: normal;
  font-weight: normal;
  src: local("Larsseit Thin"), url("LarsseitThin.woff") format("woff");
}

@font-face {
  font-family: "Larsseit Thin Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Larsseit Thin Italic"), url("LarsseitThinItalic.woff") format("woff");
}

@font-face {
  font-family: "Larsseit Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Larsseit Light Italic"), url("LarsseitLightItalic.woff") format("woff");
}

@font-face {
  font-family: "Larsseit400";
  font-style: normal;
  font-weight: 400;
  src: local("Larsseit Light"), url("LarsseitLight.woff") format("woff");
}

@font-face {
  font-family: "Larsseit500";
  font-style: normal;
  font-weight: 700;
  src: local("Larsseit Medium"), url("LarsseitMedium.woff") format("woff");
}

@font-face {
  font-family: "Larsseit Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Larsseit Medium Italic"), url("LarsseitMediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Larsseit600";
  font-style: normal;
  font-weight: normal;
  src: local("Larsseit Bold"), url("LarsseitBold.woff") format("woff");
}

@font-face {
  font-family: "Larsseit Bold Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Larsseit Bold Bold Italic"), url("LarsseitBoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Larsseit700";
  font-style: normal;
  font-weight: normal;
  src: local("Larsseit ExtraBold"), url("LarsseitExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Larsseit ExtraBold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Larsseit ExtraBold Italic"), url("LarsseitExtraBoldItalic.woff") format("woff");
}
