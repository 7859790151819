:where(.css-12jzuas).ant-table-wrapper .ant-table {
  background: unset !important;
}
.gap-table .ant-table table {
  border-collapse: separate;
  border-spacing: 0 10px;
  background: unset !important;
}
.ant-table tbody tr {
  background: #fff;
}
.ant-table tbody tr th {
  background: unset !important;
}
.gap-table input {
  accent-color: #000000 !important;
}
:where(.css-12jzuas).ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: unset !important;
}

.table-wrap {
  width: 100%;
  height: 100%;
  padding: 20px;
  padding: 0;

  @media (max-width: 500px) {
    margin-bottom: 140px;
  }
}
.table-box {
  padding: 16px;

  @media (max-width: 934px) {
    padding-top: 0px;
  }
}

.ant-table-tbody tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.ant-table-tbody tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

:where(.css-12jzuas).ant-checkbox .ant-checkbox-inner:after {
  position: absolute;
  color: #fff;
  content: "✓";
  top: 7px;
  bottom: 0;
  left: 2px;
  right: 0;
  transform: rotate(16deg) scale(1) translate(-50%, -50%);
  border: none;
}

:where(.css-12jzuas).ant-checkbox-indeterminate :after {
  position: absolute;
  color: #fff;
  content: "✓";
  /* top: 7px; */
  bottom: 0;
  left: 2px;
  right: 0;

  /* transform: rotate(16deg) scale(1) translate(-50%, -50%); */
}
/* 
  :where(.css-12jzuas).ant-checkbox-checked:after {
    position: absolute;
    color: #fff;
    content: "✓" !important;
    top: 7px;
    bottom: 0;
    left: 2px;
    right: 0;
  } */

:where(.css-dev-only-do-not-override-12jzuas).ant-checkbox-checked .ant-checkbox-inner {
  background-color: #101010 !important;
  border-color: #101010 !important;
}

:where(.css-12jzuas).ant-checkbox-checked .ant-checkbox-inner {
  background-color: #101010 !important;
  border-color: #101010 !important;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: unset !important;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-checkbox-indeterminate :after {
  position: absolute;
  color: #fff;
  content: "✓";
  /* top: 7px; */
  bottom: 0;
  left: 2px;
  right: 0;

  /* transform: rotate(16deg) scale(1) translate(-50%, -50%); */
}
:where(.css-dev-only-do-not-override-12jzuas).ant-checkbox .ant-checkbox-inner:after {
  position: absolute;
  color: #fff;
  content: "✓";
  top: 7px;
  bottom: 0;
  left: 2px;
  right: 0;
  transform: rotate(16deg) scale(1) translate(-50%, -50%);
  border: none;
}
:where(.css-dev-only-do-not-override-12jzuas).ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: unset !important;
}
