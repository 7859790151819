* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Lato, sans-serif;
}

.info-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.info-wrapper p {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #4b5563;
}
.info-wrapper:hover p {
  color: #63a3f7;
}

.info-icon path {
  color: #4b5563;
  fill: #4b5563;
}

.info-wrapper:hover .info-icon path {
  color: #63a3f7;
  fill: #63a3f7;
}

.ant-pagination .ant-pagination-item {
  border-radius: 50%;
}

.ant-picker-suffix {
  color: #656e79 !important;
  width: 20px !important;
}
.ant-picker-input > input::placeholder {
  font-family: Lato, sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #000000 !important;
}
.ant-select-selector {
  margin-top: 8px !important;
  background: #f5f6f7 !important;
  border-radius: 500px !important;
  padding: 10px !important;
  border-style: none !important;
  font-family: Lato, sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #000000 !important;
  width: 100% !important;
  height: max-content !important;
}

.PlayBlueBtn path {
  fill: #3d97f2;
}

.PlayGrayBtn path {
  fill: #4b5563;
}

th {
  font-family: Lato, sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  color: #4b5563 !important;
  white-space: nowrap;
}
td {
  font-family: Lato, sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #101010 !important;
  margin-bottom: 30px !important;
}

.StatusWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  /* margin-bottom: 10px; */
}
.tableClass-tbody tr td {
  margin: 20px 0 !important;
}

.tableClass-tbody tr {
}

:where(.css-dev-only-do-not-override-12jzuas).ant-table-wrapper .ant-table-thead > tr > th {
  background: unset !important;
  border-bottom: unset !important;
}
:where(.css-dev-only-do-not-override-12jzuas).ant-table-wrapper .ant-table {
  background: unset !important;
}

:where(.css-12jzuas).ant-table-wrapper .ant-table {
  background: unset !important;
}

.ThreeLinesWhite path {
  fill: #ffff;
  stroke: #ffff;
}
.ThreeLinesGray path {
  fill: #656e79;
  stroke: #656e79;
}

.ant-drawer-body {
  padding: 0 !important;
}
/* .ant-drawer-content {
  border-radius: 28px 28px 0px 0px;
  background: #ffffff;
  box-shadow: 0px -2px 14px rgba(0, 0, 0, 0.15) !important;
  height: 456px;
} */

:where(.css-dev-only-do-not-override-12jzuas).ant-pagination .ant-pagination-item-active {
  background-color: #ffffff;
  border-color: unset;
  border-style: none;
  box-shadow: 0px 1px 2px #cecece;
}

:where(.css-12jzuas).ant-pagination .ant-pagination-item-active {
  background-color: #ffffff;
  border-color: unset;
  border-style: none;
  box-shadow: 0px 1px 2px #cecece;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-pagination .ant-pagination-item-active a {
  color: #101010 !important;
}

:where(.css-12jzuas).ant-pagination .ant-pagination-item-active a {
  color: #101010 !important;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-pagination .ant-pagination-item a {
  color: #4b5563;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}
:where(.css-12jzuas).ant-pagination .ant-pagination-item a {
  color: #4b5563;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-picker {
  border-style: none;
  box-shadow: unset;
  padding: 0;
}

:where(.css-12jzuas).ant-picker {
  border-style: none;
  box-shadow: unset;
  padding: 0;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-picker-range .ant-picker-range-separator {
  padding: 0;
}
:where(.css-12jzuas).ant-picker-range .ant-picker-range-separator {
  padding: 0;
}
.custom-class .ant-modal-content {
  padding: 0 !important;
}
.mobileViewDiv {
  padding: 0px 24px;
}

.notification-custom .ant-popover-inner {
  padding: 0 !important;
}
:where(.css-dev-only-do-not-override-12jzuas).ant-checkbox-checked .ant-checkbox-inner {
  background: #101010;
}
.range-picker .ant-picker-input {
  width: 82px !important;
}

.ant-picker-active-bar {
  opacity: 0 !important;
}
:where(.css-dev-only-do-not-override-12jzuas).ant-picker .ant-picker-input > input {
  color: #4b5563 !important;
  font-size: 14px !important;
  font-family: Lato, sans-serif !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

@media (max-width: 480px) {
  .ant-calendar-range {
    width: 320px;
  }
  .ant-calendar-range-part {
    width: 100%;
  }
}
.teamEditSelect .ant-select-selector {
  margin-top: 0 !important;
}
.date-input {
  background: #f5f6f7;
  border-radius: 500px;
  padding: 15px;
  border-style: none;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  width: 100%;
}
.date-input > div > input {
  margin: 0 !important;
}
.date-input > div > input::placeholder {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: rgb(0, 0, 0);
}

.workspace-select {
  /* border: 1px solid red; */
  /* background-color: #ffff; */
  margin-top: 5px !important;
  filter: drop-shadow(rgba(16, 24, 40, 0.06) 0px 2px 12px) !important;
  background: rgb(255, 255, 255) !important;
  border-radius: 8px;
  border-style: none;
}
.workspace-select:focus {
  outline: none !important;
  border-style: none !important;
}
.workspace-select .ant-select-selector {
  background-color: unset !important;
  border-radius: unset !important;
  margin-top: 0 !important;
  width: 100%;
  height: 52px;
  padding: 14px 16px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgb(75, 85, 99);
}
.workspace-select .ant-select-selection-search-input::placeholder {
  font-family: Lato, sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: rgba(75, 85, 99, 0.678) !important;
}
.Crm-modal .ant-modal-content {
  padding: 0 !important;
}

.add-region-modal .ant-modal-content {
  padding: 5px !important;
}
.ant-select-selection-placeholder {
  color: rgba(75, 85, 99, 0.678) !important;
}
.ant-select-clear {
  padding-right: 50px;
  padding-top: 1px;
  background-color: transparent !important;
}
.ant-select-clear svg {
  width: 19px !important;
  height: 19px !important;
}
.ant-select-selection-search input {
  margin-top: 10px !important;
}
.ant-select-selection-search {
  background: transparent;
  z-index: 2;
}
.ant-tooltip .ant-tooltip-inner {
  width: max-content !important;
  white-space: nowrap !important;
}
.ant-table-wrapper {
  margin-bottom: 48px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type="number"] {
  -moz-appearance: textfield;
} */

.mb-0 {
  margin-bottom: 0 !important;
}

/* .react-tel-input {
  height: 52px;
  background: #f5f6f7;
  border-radius: 500px;
  border-style: none;
  padding-left: 16px;
  margin: 8px 0 16px 0;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #000000;
} */
.react-tel-input .form-control {
  height: 52px !important;
  width: 100% !important;
  background: #f5f6f7 !important;
  border-radius: 500px !important;
  border-style: none !important;
  padding-left: 16px !important;
  margin: 3px 0 !important;
  font-family: Lato, sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: -0.01em !important;
  color: #000000 !important;
}

.react-tel-input .form-control:focus {
  outline: none;
}
.selected-flag {
  display: none;
}
.flag-dropdown {
  display: none;
}

.popoverClass-inner {
  padding: 12px 0px !important;
  border-radius: 5px !important;
}

.modalStyle .modalStyle-content {
  padding: 0 !important;
}

.profileInfo-modal-content {
  z-index: 9999;
}
.group-modal-content {
  padding: 0 !important;
}

.Image-modal-content {
  padding: 6px !important;
}
.Image-modal-close-icon {
  background-color: white;
  padding: 2px;
  border-radius: 50%;
}
.Image-modal-close-icon svg {
  color: red;
}

.addPopOverClass .addPopOverClass-inner {
  /* border: 2px solid #d0bebe; */
  padding: 8px;
}

.step3select .ant-select-selector {
  margin-top: 0 !important;
  height: 55px !important;
}
.step3select .ant-select-selection-placeholder {
  padding: 4px 16px 0 0 !important;
}

.search-modal-content {
  padding: 0px !important;
}
.search-modal-close {
  top: 24px !important;
}

.search-modal-body {
  border-radius: 7px;
  overflow: hidden;
}
